<template>
    <inner-page-card :route-path="lineBackPath" heading="Customer Details" :link-back="true">

        <div v-if="loading" class="fl-eq">
            <loading-animation/>
        </div>

        <div v-else>
            <table class="table table-bordered border-r-2 mb-3">
                <thead>
                <tr class="bg-4">
                    <th rowspan="1" colspan="2" class="pos-r">
                        <div class="font-inter-medium text-center fs-lg-2">
                            User Id - {{ details.user_id }}
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody class="section-items">
                <!--                web-->
                <div class="d-sm-none">
                    <tr class="w-100 fl-x">
                        <td class="font-inter-medium w-50" colspan="1">
                            <div class="fl-x fl-a-c">
                        <span class="pl-3">
                            <img src="../../../assets/web/super-admin/bidders/hi.png" style="height: 2rem;" alt="">
                        </span>
                                <span class="font-inter-semi-bold fs-3 pl-3">Total Participation : </span>
                                <span class="text-primary font-inter-semi-bold fs-3 pl-3">{{
                                        details.total_participation
                                    }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium" colspan="1">
                            <div class="fl-x fl-a-c">
                        <span class="pl-3">
                            <img src="../../../assets/web/super-admin/bidders/trpohy.png" style="height: 2rem;" alt="">
                        </span>
                                <span class="font-inter-semi-bold fs-3 pl-3">Total Wins : </span>
                                <span class="text-primary font-inter-semi-bold fs-3 pl-3">{{
                                        details.total_wins
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </div>
                <div class="d-lg-none">

                    <tr class="font-inter-medium w-50" colspan="1">
                        <div class="fl-x fl-a-c">
                        <span class="pl-3 mt-3">
                            <img src="../../../assets/web/super-admin/bidders/hi.png" style="height: 2rem;" alt="">
                        </span>
                            <span class="font-inter-semi-bold fs-3 pl-3 mt-3">Total Participation : </span>
                            <span class="text-primary font-inter-semi-bold fs-3 pl-3 mt-3">{{
                                    details.total_participation
                                }}</span>
                        </div>
                    </tr>
                    <tr class="font-inter-medium" colspan="1">
                        <div class="fl-x fl-a-c">
                        <span class="pl-3 mb-3 mt-2">
                            <img src="../../../assets/web/super-admin/bidders/trpohy.png" style="height: 2rem;" alt="">
                        </span>
                            <span class="font-inter-semi-bold fs-3 pl-3 mb-3">Total Wins : </span>
                            <span class="text-primary font-inter-semi-bold fs-3 pl-3 mb-3">{{
                                    details.total_wins
                                }}</span>
                        </div>
                    </tr>

                </div>
                </tbody>
            </table>
            <!--            web-->
            <div class="d-sm-none">
                <table class="table table-bordered border-r-2 mb-0">
                    <thead>
                    <tr class="bg-4">
                        <th rowspan="1" colspan="2" class="pos-r">
                            <div class="font-inter-medium text-center fs-lg-2">
                                Bidder Details
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="section-items">
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Registration type : </span>
                                <span>{{ details.customer_type }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>First Name : </span>
                                <span>{{ details.first_name }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Last Name : </span>
                                <span>{{ details.last_name }}</span>
                            </div>
                        </td>
                        <td class="fnt-inter-medium">
                            <div>
                                <span>Email : </span>
                                <span>{{ details.email }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Mobile : </span>
                                <span>{{ details.mobile_number }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>Father's Name : </span>
                                <span>{{ details.father_husband_name }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Country : </span>
                                <span>{{ details.country }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>State : </span>
                                <span>{{ details.state }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>District : </span>
                                <span>{{ details.district }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>Pincode : </span>
                                <span>{{ details.pincode }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>PAN Number : </span>
                                <span>{{ details.pan }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>Uploaded Documents : </span>
                                <span v-if="details.emd_payment_receipt"><a
                                    @click="setDownload(details.emd_payment_receipt)"
                                    class="text-decoration-none">View</a></span>
                                <span v-else>Not Uploaded</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="font-inter-medium">
                            Address: {{ details.address }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <!--            responsive-->
            <div class="col-lg-none">
                <table class="table table-bordered border-r-2 mb-0">
                    <thead>
                    <tr class="bg-4">
                        <th rowspan="1" colspan="2" class="pos-r">
                            <div class="font-inter-medium text-center fs-lg-2">
                                Bidder Details
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody class="section-items">
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Registration type : </span>
                                <span>{{ details.customer_type }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>First Name : </span>
                                <span>{{ details.first_name }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Last Name : </span>
                                <span>{{ details.last_name }}</span>
                            </div>
                        </td>
                         <td class="font-inter-medium">
                            <div>
                                <span>Mobile : </span>
                                <span>{{ details.mobile_number }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>

                        <td colspan="2" class="fnt-inter-medium">
                            <div>
                                <span>Email : </span>
                                <span>{{ details.email }}</span>
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Father's Name : </span>
                                <span>{{ details.father_husband_name }}</span>
                            </div>
                        </td>
                        <td class="font-inter-medium">
                            <div>
                                <span>Country : </span>
                                <span>{{ details.country }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>State : </span>
                                <span>{{ details.state }}</span>
                            </div>
                        </td>

                        <td class="font-inter-medium">
                            <div>
                                <span>District : </span>
                                <span>{{ details.district }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="font-inter-medium">
                            <div>
                                <span>Pincode : </span>
                                <span>{{ details.pincode }}</span>
                            </div>
                        </td>

                        <td class="font-inter-medium">
                            <div>
                                <span>PAN Number : </span>
                                <span>{{ details.pan }}</span>
                            </div>
                        </td>
                        <tr>
                        <td colspan="2" class="font-inter-medium">
                            <div>
                                <span>Uploaded Documents : </span>
                                <span v-if="details.emd_payment_receipt"><a
                                    @click="setDownload(details.emd_payment_receipt)"
                                    class="text-decoration-none">View</a></span>
                                <span v-else>Not Uploaded</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="font-inter-medium">
                            Address: {{ details.address }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </inner-page-card>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name     : 'CustomerDetails',
    computed : {
        lineBackPath () {
            return this.selectedIndex ? '/manage-users/' + this.selectedIndex + '/' : '';
        }
    },

    data () {
        return {
            loading       : true,
            details       : [],
            id            : this.$route.params.id,
            selectedIndex : this.$route.params.selectedIndex
        };
    },

    mounted () {
        this.loadDetails();
    },

    methods : {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.userList.customerDetails, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.loading = false;
                console.log('json.data', json.data);
            } else {
                this.loading = false;
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        },

        setDownload (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped>

</style>
